label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.inline-form-item {
  display: inline-block;
  margin-right: 100px;
  /* Adjust the margin as needed */
}


.log {
  display: flex;
  justify-content: center;
  align-items: center;

  .card-wrapper {
    text-align: center;
    // position: absolute;
    // top: 40%;
    // left: 50%;
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    min-width: 570px;

    .ant-card-body {
      padding: 60px;
    }

    @include media('<md') {
      min-width: 320px;

      .ant-card-body {
        padding: 34px;

        .login-logo {
          max-width: 150px !important;
        }

        // .ant-card-meta-title {
        //   font-size: 16px;
        // }
      }
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: center;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.modal {
  .ant-form-item.upload-wrapper.right {
    .ant-form-item-control {
      justify-content: center;
    }
  }
}

.ant-card {
  // border-radius: $table-border-radius;
  // box-shadow: $table-box-shadow;

  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.4) 100%);
  border: 0;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 10px;

  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.forgotten-password {
  margin-top: 15px;
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #ffffff;
  background: $primary-color;
  text-transform: capitalize;
  border-radius: $table-border-radius-top;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

// .panel-heading .panel-title span {
//   background-color: $primary-color !important;
// }

.panel-heading .panel-title span {
  color: $k90;
  background-color: transparent !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
}

input,
select {
  font-size: 100% !important;
}

//ikonice search pen kanta oko

.ant-input-affix-wrapper {
  svg {
    fill: #b3b3b3;
  }
}

.icon-unlock {
  svg {
    fill: #b3b3b3;
  }
}

.ant-avatar.ant-avatar-circle {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead>tr>th,
.ant-table.ant-table-middle .ant-table-tbody>tr>td,
.ant-table.ant-table-middle tfoot>tr>th,
.ant-table.ant-table-middle tfoot>tr>td {
  padding: 12px 27px;
}

.refundTable .ant-table.ant-table-middle .ant-table-title,
.refundTable .ant-table.ant-table-middle .ant-table-footer,
.refundTable .ant-table.ant-table-middle .ant-table-thead>tr>th,
.refundTable .ant-table.ant-table-middle .ant-table-tbody>tr>td,
.refundTable .ant-table.ant-table-middle tfoot>tr>th,
.refundTable .ant-table.ant-table-middle tfoot>tr>td {
  padding: 12px 8px;
}

.refundTable .ant-table-bordered .ant-table-thead>tr>th {
  padding-top: 35px !important;
}

.ant-table-cell {
  padding-left: 20px;
  // text-align: center !important;
}

.ant-form-vertical .ant-form-item-label>label,
.ant-col-24.ant-form-item-label>label,
.ant-col-xl-24.ant-form-item-label>label {
  margin-top: 10px;
}

//petak

// .panel-primary .panel-body {
//   margin-top: 25px;
// }

.panel-body {
  #dnd-dropzone {
    margin-bottom: 20px !important;
  }
}

.panel-body {
  #featureImage {
    padding-top: 13px;
  }
}

.panel-body {
  #gallery {
    padding-top: 13px;
  }
}

.panel-body {
  #image {
    padding-top: 13px;
  }
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  border-color: #c1b9f0;
}

//log edit user + avatar
.ant-layout-sider-children .ant-avatar.ant-avatar-circle {
  display: block;
  margin-left: 85px;
  margin-bottom: 10px;
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}

// Role Popup single instead multiple on the left
// .ant-select-single.ant-select-show-arrow
// .ant-select-selection-item,
// .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
//   text-align: left;
// }

// @-moz-document url-prefix() {
//   html,
//   body {
//     scrollbar-width: none;
//   }
// }

* {
  scrollbar-width: none;
}

.ant-btn.ant-btn-primary.ant-btn-sm {
  width: auto;
}

.ant-btn {
  min-width: 120px;
}

* {
  -ms-overflow-style: none;
}

//body scroll orders
.table-orders-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-orders-okvir .ant-table.ant-table-middle {
  border-radius: 10px;
}

.table-user-okvir .ant-table-body {
  // max-height: 85vh !important;
  overflow-y: auto !important;
  max-height: inherit !important;
  background: white;
}

.table-data-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

.table-tags-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

.table-data-okvir .ant-table-pagination.ant-pagination {
  padding-bottom: 17px !important;
}

.table-attribute-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

.table-declaration-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

.table-newsletter-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

.table-refund-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

.table-invoice-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

.table-post-okvir .ant-table-body {
  // max-height: 85vh !important;
  background: white;
}

// paginacija topCenter i razmaci
.ant-table-pagination.ant-pagination {
  margin: 0;
  background: white;
  padding-bottom: 27px;
  margin-top: -57px;
}

//paginacija razmak gore
.usersWrapper .actions-block {
  margin-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
}

// button add user i slicni
.ant-btn,
.ant-input-affix-wrapper-borderless {
  z-index: 5;
}

// search polje users
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless {
  max-width: 400px;
  position: relative;
  padding: 0;
  height: 44px;
  padding-left: 15px;
  padding-right: 59px;
  border-radius: 90px;
  border: 1px solid #e3e3e3;


  & .ant-input-prefix {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

// pozadina active page pagination
.ant-pagination-item-active {
  // background: linear-gradient(180deg, #3372ed 0%, #1138c2 100%);
  background: $primary-color;
  border-radius: 4px;
  min-width: 26px !important;
  height: 26px !important;
}

// pozadina paginacija on hover
.ant-pagination-item-active:hover a,
.ant-pagination-item-active:hover a:visited {
  color: white;
  // background: $secondary-color;
}

// kada nema podataka no-data-box
.no-data-box {
  background: white;
  border-radius: 8px;

  h2 {
    color: grey;
    font-size: 30px;
    padding-top: 60px;
    margin-bottom: 0;
  }
}

.link-forgot-password {
  color: black !important;
}

.link-forgot-password:hover {
  color: white !important;
  text-decoration: none;
}

.anticon.anticon-export svg {
  margin-right: 10px;
  vertical-align: -0.3em;
}

.ant-drawer-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 19px;
}

.ant-drawer-header-title {
  display: flex;
}

.ant-drawer-header-title button {
  order: 1;
}

.ant-drawer-header-title {
  order: 2;
}

.ant-drawer-header {
  padding: 60px 60px 30px 60px;
  border: 0;
}

.ant-drawer-body {
  display: grid;
  padding: 30px 60px 60px 60px;
}

.ant-drawer-body button[type="button"] {
  border: 0;
  color: hsla(0, 0%, 38%, 1);
  font-weight: 600;
}

.ant-drawer-body .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 90px;
  min-height: 44px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 90px;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.panel-body {
  padding: 0;
}

.email-v {
  .ant-card-meta-title {
    text-align: center !important;
  }
}

.ant-form {
  & .ant-input {
    padding: 10px 16px;
  }

  & .ant-input,
  & .ant-input-affix-wrapper {
    border-radius: 90px !important;
    background-color: white !important;
  }

  & .ant-input-affix-wrapper {
    padding: 0;

    & input {
      padding: 10px 16px;
    }
  }

  & .ant-input-suffix {
    width: 40px;
    margin: 0;
    justify-content: center;
  }
}


.ant-form {
  .link {
    display: flex;
    margin-top: 10px;

    &>.ant-row {
      justify-items: flex-start;
      flex-flow: inherit
    }

    & .ant-form-item-control-input {
      justify-content: flex-start;
    }

    & .link-forgot-password {
      display: flex;
      column-gap: 10px;

      & .link-primary {
        color: $k90;
        font-weight: 400;
      }
    }
  }
}


.add-values,
.add-atributes,
.add-categories {
  border-left: 1px solid #e8e8e8;

  & .panel-title {
    font-size: 24px;
    font-weight: 600;
  }

  & .ant-form.ant-form-vertical {
    padding: 1rem;
  }
}

.add-values {
  min-height: calc(100vh - 160px);
}

.ant-card-head {
  margin-bottom: 0;
  border: 0;
}

::placeholder {
  color: #93949D !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #93949D !important;
}

::-ms-input-placeholder {
  color: #93949D !important;
}

.table-import-data .actions-block .ant-upload {
  & button {
    margin-left: 0 !important;
  }
}

.ant-table-filter-dropdown {
  & .ant-input {
    border-radius: 90px;
    outline: 0;
    margin-bottom: 15px !important;
  }

  & .ant-input:focus,
  & .ant-input-focused {
    border-color: #c1b9f0 !important;
    box-shadow: 0 0 0 2px #c1b9f0 !important;
  }

  & button {
    padding-left: 0 !important;
    padding-right: 0 !important;
    line-height: 30px !important;
    height: 30px !important;
    min-height: auto !important;

    &.ant-btn {
      min-width: auto !important;
    }

    &.ant-btn-secondary {
      transition: all 0.3s ease;

      &:hover {
        color: $primary-color;
        border-color: $primary-color;
      }
    }
  }
}

.ant-table-filter-dropdown-btns {
  column-gap: 8px;

  & .ant-btn {
    width: 70px !important;
    min-width: 70px !important;
  }

  & .ant-btn-link {
    border-color: #d9d9d9 !important;
  }

  & .ant-btn-link:not(.ant-btn-link[disabled]) {
    color: rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;

    &:hover {
      color: $primary-color;
      border-color: $primary-color !important;
    }
  }
}

//

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: #f3f1ff !important;
  border-radius: 90px;
}

.ant-dropdown-menu-item:not(:last-child) {
  margin-bottom: 4px;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #333;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #c1b9f0;
}

.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #c1b9f0 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
  box-shadow: 0 0 0 2px #c1b9f0;
  border-color: #c1b9f0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #c1b9f0 !important;
}

.ant-radio-inner:after {
  background-color: $primary-color !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #f3f1ff !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #c1b9f0;
  box-shadow: 0 0 0 2px #c1b9f0;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #c1b9f0 !important;
  box-shadow: 0 0 0 2px #c1b9f0 !important;
}

.ant-radio-checked::after {
  border-color: #c1b9f0 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #c1b9f0 !important;
}


.ant-form .ant-input input,
.ant-form .ant-input-affix-wrapper input {
  border-color: #c1b9f0 !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #c1b9f0;
  box-shadow: 0 0 0 2px #c1b9f0;
}

.jodit-container {
  transition: border-color 0.3s ease;
}

.jodit-container:hover {
  border-color: #c1b9f0 !important;
}

.jodit-container:focus {
  border-color: #c1b9f0 !important;
  box-shadow: 0 0 0 2px #c1b9f0 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: $primary-color !important;

}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: $primary-color !important;
}

.ant-checkbox-checked::after {
  border-color: $primary-color !important
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  border-radius: 90px !important;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #c1b9f0 !important;
}

.ant-modal-content {
  & .ant-btn {
    height: 40px;
    min-height: auto !important;
  }

  & .ant-btn-default {
    transition: all 0.3s ease;

    &:focus,
    &:hover {
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}

.ant-modal-content {
  border-radius: 10px !important;

  & .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.panel .panel {
  border-radius: 10px !important;
  background-color: #f3f1ff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15) !important;
}

.custom-button {
  border-radius: 50% !important;
}

.custom-button:hover,
.custom-button:active {
  color: $primary-color !important;
  border-color: $primary-color !important;
}

.rst__rowContents {
  border-radius: 12px !important;
  padding: 15px 30px !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15) !important;
  border-color: #cfcfcf;
  height: auto !important;
}

.rst__rowToolbar {
  column-gap: 8px;

  &>div {
    margin: 0;
    margin-left: 0;
  }
}

.btn-close {
  position: relative;
  width: 34px;
  transition: transform 0.3s ease;

  & .icon {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 28px;
    font-weight: 100;
    opacity: 0.75;
  }

  &:hover {
    transform: rotate(90deg);
  }
}

.ant-form .ant-input,
.ant-form .ant-input-affix-wrapper {
  border-radius: 30px !important;

}

textarea:hover,
textarea:active,
textarea:visited,
textarea:focus {
  border-color: #c1b9f0 !important;
}

textarea:hover {
  border-color: #c1b9f0 !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: transparent !important;
}

.ant-drawer-content-wrapper {
  .ant-drawer-header-title {
    & button {
      position: relative;
      padding: 0;
      margin-right: 0;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: rotate(90deg);
      }

      &>span {
        position: relative;
        display: grid;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '\00d7';
          clear: both;
          display: block;
          font-size: 36px;
          line-height: 32px;
          font-weight: 100;
        }

        &>svg {
          display: none;
        }
      }
    }
  }
}